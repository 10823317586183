import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import GetStartedHero from '../components/organisms/GetStartedHero';


const GetStartedPage = ({ data: { page } }) => (
  <Layout>
    <Seo post={page} />

    <GetStartedHero
      {...page.getStarted}
      successMessage={page.getStarted.getStartedSuccessMessage}
    />
  </Layout>
);

export const pageQuery = graphql`

  query GetStartedPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      getStarted {
        title
        body
        logos {
          logo {
            ...Image
          }
        }
        form {
          title
          body
        }
        getStartedSuccessMessage {
          title
          body
        }
      }
    }
  }
`;

export default GetStartedPage;
