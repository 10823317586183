import { ErrorMessage, Formik } from 'formik';
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Checkbox from '../atoms/Checkbox';
import Select from '../atoms/Select';
import useFetch from 'use-http';
import { industries } from '../../config/industries';
import { mediaSpend } from '../../config/mediaSpend';
import { companyType } from '../../config/companyType';
import { AnimatePresence } from 'framer-motion';
import FormSubmittedOverlay from './FormSubmittedOverlay';
import usePardotSubmit from '../../hooks/use-pardot-submit';

interface GetStartedFormProps {
  title: string;
  body: string;
  successMessage: {
    title: string;
    body: string;
  };
}

const GetStartedFrom: React.FC<GetStartedFormProps> = ({
  title,
  body,
  successMessage,
}) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company: '',
    company_type: '',
    industry: '',
    media_spend: '',
    terms: false,
  };

  const schema = Yup.object().shape({
    first_name: Yup.string().required('This field is required'),
    last_name: Yup.string().required('This field is required'),
    phone: Yup.string().required('This field is required'),
    email: Yup.string()
      .email('Please provide valid email')
      .required('This field is required'),
    company: Yup.string().required('This field is required'),
    company_type: Yup.string().required('This field is required'),
    industry: Yup.string().required('This field is required'),
    media_spend: Yup.string().required('This field is required'),
    terms: Yup.bool()
      .required('Please agree to the privacy policy')
      .oneOf([true], 'Please agree to the privacy policy'),
  });

  const submit = async (data) => {
    setLoading(true);
    usePardotSubmit(
      'https://go.spirable.com/l/843243/2021-02-25/mpyqzk',
      data,
      setResult,
    );
  };

  useEffect(() => {
    console.log(result);
    setLoading(false);
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={submit}
    >
      {({
        values: {
          first_name,
          last_name,
          phone,
          email,
          company,
          company_type,
          industry,
          media_spend,
          terms,
        },
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className="relative">
          <div
            className="bg-cover rounded "
            style={{ backgroundImage: 'url(/images/get-started-form-bg.jpg)' }}
          >
            <div className="relative p-8 overflow-hidden bg-white rounded-lg -top-4 md:-top-8 -left-4 md:-left-8 lg:p-16 shadow-form">
              <AnimatePresence>
                {result === 'success' && (
                  <FormSubmittedOverlay {...successMessage} />
                )}
              </AnimatePresence>

              <h2 className="u-h4 mb-4.5">{title}</h2>

              <p className="hidden mb-5 text-sm leading-normal md:leading-7 md:text-base text-body-copy md:block">
                {body}
              </p>

              <h3 className="text-lg font-semibold leading-relaxed mb-4.5">
                About you
              </h3>

              <div className="mb-8 space-y-5">
                <div>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    className="u-input"
                    value={first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="first_name"
                    component="div"
                  />
                </div>

                <div>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    className="u-input"
                    value={last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="last_name"
                    component="div"
                  />
                </div>

                <div>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your phone number"
                    className="u-input"
                    value={phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="phone"
                    component="div"
                  />
                </div>

                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your work email"
                    className="u-input"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="email"
                    component="div"
                  />
                </div>
              </div>

              <h3 className="text-lg font-semibold leading-relaxed mb-4.5">
                About your business
              </h3>

              <div className="mb-10 space-y-5">
                <div>
                  <input
                    type="text"
                    name="company"
                    placeholder="Your company"
                    className="u-input"
                    value={company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="company"
                    component="div"
                  />
                </div>

                <div>
                  <Select
                    name="company_type"
                    value={company_type}
                    onChange={handleChange}
                    className="w-full u-form-select"
                    onBlur={handleBlur}
                  >
                    <option>Company Type</option>
                    {companyType.map((value) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="company_type"
                    component="div"
                  />
                </div>

                <div>
                  <Select
                    name="industry"
                    value={industry}
                    onChange={handleChange}
                    className="w-full u-form-select"
                    onBlur={handleBlur}
                  >
                    <option>Select your industry</option>
                    {industries.map((value) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="industry"
                    component="div"
                  />
                </div>

                <div>
                  <Select
                    name="media_spend"
                    value={media_spend}
                    onChange={handleChange}
                    className="w-full u-form-select"
                    onBlur={handleBlur}
                  >
                    <option>Media Spend</option>
                    {mediaSpend.map((value) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="media_spend"
                    component="div"
                  />
                </div>
              </div>

              <div className="mb-12">
                <Checkbox
                  label={
                    <>
                      I consent to receive newsletters and other communications
                      from Spirable. Please read our{' '}
                      <Link
                        to="/privacy"
                        className="underline transition focus:outline-none focus:ring focus:border-primary-red focus:ring-primary-red focus:ring-opacity-20 focus:text-black hover:text-black"
                      >
                        Privacy Policy
                      </Link>{' '}
                      to see how we protect and manage your personal data.
                    </>
                  }
                  checked={terms}
                  onChange={(value) => setFieldValue('terms', value)}
                />
                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="terms"
                  component="div"
                />
              </div>

              <button
                type="submit"
                className="w-full py-3 font-semibold leading-7 text-white transition-all bg-primary-red focus:outline-none focus:ring focus:border-primary-red focus:ring-primary-red focus:ring-opacity-20 hover:bg-black"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Request demo'}
              </button>

              {/* {error && (
                <div className="mt-4 text-sm text-primary-red">
                  {error.message}
                </div>
              )} */}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default GetStartedFrom;
