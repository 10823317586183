import React from 'react';
import GetStartedFrom from '../molecules/GetStartedForm';

interface GetStartedHeroProps {
  title: string;
  body: string;
  logos: {
    logo: {
      sourceUrl: string;
      altText: string;
    };
  }[];
  form: {
    title: string;
    body: string;
  };
  successMessage: {
    title: string;
    body: string;
  };
}

const GetStartedHero: React.FC<GetStartedHeroProps> = ({
  title,
  body,
  logos,
  form,
  successMessage,
}) => (
  <section className="pt-16 pb-20 bg-purgatory-grey lg:pt-32 lg:pb-40">
    <div className="u-container lg:flex">
      <div className="mb-10 md:mb-16 lg:w-1/2 lg:pr-16 lg:mb-0 md:mt-20">
        <h1 className="u-h2" dangerouslySetInnerHTML={{ __html: title }} />
        <div
          className="max-w-md leading-normal u-p1"
          dangerouslySetInnerHTML={{ __html: body }}
        />

        <div className="grid grid-cols-4 gap-0 mt-8 md:mt-12">
          {logos.map(({ logo: { sourceUrl, altText } }, index) => (
            <div className="" key={index}>
              <img
                src={sourceUrl}
                alt={altText}
                className="block object-contain w-auto h-20"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="pl-4 md:pl-8 lg:w-1/2 lg:pl-0">
        <GetStartedFrom {...form} successMessage={successMessage} />
      </div>
    </div>
  </section>
);

export default GetStartedHero;
